<template>
    <div id="config-cash_register" class="container-fluid mb-3">

        <div class="row">
            <titles title="Configuración" subtitle="Caja" ></titles>
            <title-tabs :array_titletabs="title_cashregister"></title-tabs>

            <div class="col-12 bg-white shadow-sm py-3 px-0 rounded border-box">

                <div class="row p-0">

                    <div class="col-6 col-xxl-4 pb-3">
                        <span class="text-black opensans-semibold ki--description-sm">
                            Tamaño en pantalla de caja
                        </span>
                        <div class="border-2 border-light mt-2 p-2">
                            <ki-input v-model="font_size" 
                                label="Tamaño de letras" 
                                type="number" 
                                placeholder="0 puntos" 
                                class="opensans-semibold">
                            </ki-input>

                            <ki-input v-model="base_line" 
                                label="Altura a reglón" 
                                type="number" 
                                placeholder="0 puntos" 
                                class="opensans-semibold mt-1 mb-4">
                            </ki-input>
                        </div>
                    </div>

                    <div class="col-6 col-xxl-4">

                        <span class="text-black opensans-semibold ki--description-sm">
                            Opciones de pantalla de caja
                        </span>

                        <div class="border-2 border-light mt-2 p-2 d-flex flex-column align-items-start">
                            <ki-checkbox label="Pedir cantidad despues de leer código" 
                                fill class="text-black opensans-semibold " 
                                style="font-size:13px">
                            </ki-checkbox>
                            <ki-checkbox label="Fijar precios" 
                                fill class="text-black opensans-semibold mt-2" 
                                style="font-size:13px">
                            </ki-checkbox>
                            <ki-checkbox label="Mostrar total en dólares" 
                                fill class="text-black opensans-semibold mt-2" 
                                style="font-size:13px">
                            </ki-checkbox>
                            <ki-checkbox label="Mostrar teclado numérico" 
                                fill class="text-black opensans-semibold mt-2" 
                                style="font-size:13px">
                            </ki-checkbox>
                            <ki-checkbox label="No descontar existencias en apartados" 
                                fill class="text-black opensans-semibold mt-2" 
                                style="font-size:13px">
                            </ki-checkbox>
                        </div>
                    </div>

                    <div class="col-6 col-xxl-4">

                        <span class="text-black opensans-semibold ki--description-sm">
                            Opciones de pantalla de cobro
                        </span>

                        <div class="row p-0 border-2 border-light mt-2 p-2">
                            <div class="col-12 p-0">
                                <ki-input v-model="cash_limit_in_box" 
                                    label="Limite de efectivo en caja:" 
                                    type="number" 
                                    placeholder="$00.00" 
                                    class="opensans-semibold mb-2" full>
                                </ki-input>
                            </div>
                            <div class="col-12 p-0 d-flex flex-column align-items-start">
                                <ki-checkbox 
                                    label="Forzar captura de pago" 
                                    class="text-black opensans-semibold " 
                                    style="font-size:13px" fill>
                                </ki-checkbox>
                                <ki-checkbox label="Forzar afectuación a bancos" 
                                    fill class="text-black opensans-semibold mt-2" 
                                    style="font-size:13px">
                                </ki-checkbox>
                                <ki-checkbox label="Enviar a crédito automáticamente" 
                                    class="text-black opensans-semibold mt-2" 
                                    style="font-size:13px" fill>
                                </ki-checkbox>
                                <ki-checkbox label="Fijar vendedor"  
                                    class="text-black opensans-semibold mt-2" 
                                    style="font-size:13px" fill>
                                </ki-checkbox>
                                <ki-checkbox label="Notas en orden alfabético" 
                                    class="text-black opensans-semibold mt-2" 
                                    style="font-size:13px" fill>
                                </ki-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 col-xxl-2">

                        <span class="text-black opensans-semibold ki--description-sm">
                            Anchos de columna
                        </span>

                        <div class="border-2 border-light mt-2 p-2">
                            <ki-input v-model="quantity" 
                                label="Cantidad" 
                                type="number" 
                                placeholder="0 pixeles"  
                                class="opensans-semibold">
                            </ki-input>
                            <ki-input v-model="product" 
                                label="Producto" 
                                type="number" 
                                placeholder="0 pixeles" 
                                class="opensans-semibold my-2">
                            </ki-input>
                            <ki-input v-model="price" 
                                label="Precio" 
                                type="number" 
                                placeholder="0 pixeles" 
                                class="opensans-semibold my-2">
                            </ki-input>
                            <ki-input v-model="total" 
                                label="Total" 
                                type="number" 
                                placeholder="0 pixeles" 
                                class="opensans-semibold my-2">
                            </ki-input>
                            <ki-input v-model="code" 
                                label="Código/Externo" 
                                type="number" 
                                placeholder="0 pixeles" 
                                class="opensans-semibold my-2">
                            </ki-input>
                            <ki-input v-model="in_existence" 
                                label="Existencias" 
                                type="number" 
                                placeholder="0 pixeles" 
                                class="opensans-semibold my-2">
                            </ki-input>
                        </div>
                    </div>

                    <div class="col-6 col-xxl-5">
                        <span class="text-black opensans-semibold ki--description-sm">
                            PLU
                        </span>
                        <div class="row border-2 border-light p-2">
                            <div class="col-xxl-4 p-0 d-flex flex-column align-items-start">
                                <ki-checkbox label="Activar PLU" 
                                    class="text-black opensans-semibold" 
                                    style="font-size:13px" fill >
                                </ki-checkbox>
                                <ki-input v-model="code_length" 
                                    label="Longitud del código:" 
                                    type="number" 
                                    placeholder="0 pixeles" 
                                    class="opensans-semibold mt-2 ml-2">
                                </ki-input>
                            </div>

                            <div class="col-12 col-xxl-8 p-0 d-flex flex-column align-items-start">
                                <label class="text-black opensans-semibold" style="font-size: 13px;">
                                    Otros
                                </label>
                                <ki-checkbox label="Incluir ceros (0) en el PLU"  
                                    class="text-black opensans-semibold" 
                                    style="font-size:13px" fill>
                                </ki-checkbox>
                            </div>

                            <div class="col-6 col-lg-4 mt-2 pl-0 pr-2" style="height: 268px">
                                <div class="row">
                                    <label class="col-12 p-0 pl-1 text-black opensans-semibold" style="font-size: 13px;">
                                        Peso
                                    </label>
                                    <div class="col-12 bg_gray p-0" style="height: 235px">
                                        <ki-input  label="Posición:" 
                                            type="number" 
                                            placeholder="0 pixeles" 
                                            class="opensans-semibold mx-2 pt-2" 
                                            full>
                                        </ki-input>
                                        <ki-input v-model="s" 
                                            label="Enteros:" 
                                            type="number" 
                                            placeholder="0 pixeles" 
                                            class="opensans-semibold mt-2 mx-2" 
                                            full>
                                        </ki-input>
                                        <ki-input label="Longitud:" 
                                            type="number" 
                                            placeholder="0 pixeles" 
                                            class="opensans-semibold mt-2 mx-2" 
                                            full>
                                        </ki-input>
                                    </div>
                                </div>  
                            </div>

                            <div class="col-6 col-lg-4 mt-2 pl-0 pr-2" style="height: 268px">
                                <div class="row">
                                    <label class="col-12 p-0 pl-1 text-black opensans-semibold" style="font-size: 13px;">
                                        Identificación
                                    </label>
                                    <div class="col-12 bg_gray" style="height: 235px">
                                        <ki-input 
                                            label="Longitud:" 
                                            type="number" 
                                            placeholder="0 pixeles" 
                                            class="opensans-semibold" 
                                            full>
                                        </ki-input>
                                        <ki-input label="Identificación:" 
                                            type="number" 
                                            placeholder="0 pixeles" 
                                            class="opensans-semibold mt-2" 
                                            full>
                                        </ki-input>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 mt-2 pl-0 pr-0" style="height: 268px">
                                <div class="row">
                                    <label class="col-12 p-0 pl-1 text-black opensans-semibold" style="font-size: 13px;">
                                        PLU
                                    </label>

                                    <div class="col-12 bg_gray p-0" style="height: 235px">
                                        <ki-input label="Posición:" 
                                            type="number" 
                                            placeholder="0 pixeles" 
                                            class="opensans-semibold pt-2 ml-2 pr-2"
                                            full>
                                        </ki-input>
                                        <ki-input label="Longitud:" 
                                            type="number" 
                                            placeholder="0 pixeles" 
                                            class="opensans-semibold ml-2 mt-2 p-0 pr-2"
                                            full>
                                        </ki-input>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-6 col-xxl-5">
                        <span class="text-black opensans-semibold ki--description-sm">
                            Ventas gráficas
                        </span>
                        <div class="row px-2 pt-1 border-2 border-light mt-2 pb-1">
                            <div class="col p-0">
                                <div class="row p-0 text-dark">

                                    <div class="col-12 col-xxl-6 p-xxl-0 p-2">
                                        <div class="row p-0" style="font-size: 12px;">

                                            <div class="col-12 p-0 d-flex">
                                                <ki-checkbox label="Activar ventanas gráficas"
                                                    fill/>
                                            </div>

                                            <div class="col-12 p-0">
                                                <ki-input label="Ancho panel familias:"
                                                    placeholder="0 pixeles"
                                                    type="text"
                                                    full>
                                                </ki-input>
                                            </div>
                                
                                            <div class="col-12 p-0">
                                                <ki-input label="Alto panel familias:"
                                                    placeholder="0 pixeles"
                                                    type="text"
                                                    full>
                                                </ki-input>
                                            </div>

                                            <div class="col-12 p-0">
                                                <ki-input label="Ancho panel productos:"
                                                    placeholder="0 pixeles"
                                                    type="text"
                                                    full>
                                                </ki-input>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-12 col-xxl-6 py-0 px-xl-1 px-xxl-2">
                                        <div class="row" style="font-size: 12px;">

                                            <div class="col-12 p-0">
                                                <p class="m-0">
                                                    Productos
                                                </p>
                                            </div>

                                            <div class="col-12 d-flex p-0">
                                                <ki-checkbox label="Activar botón productos"
                                                    fill/>
                                            </div>

                                            <div class="col-12 d-flex p-0">
                                                <ki-checkbox label="Nombre del producto"
                                                    fill/>
                                            </div>

                                            <div class="col-12 p-0">
                                                <ki-input label="Longitud:"
                                                    placeholder="0 pixeles"
                                                    type="text"
                                                    full>
                                                </ki-input>
                                            </div>

                                            <div class="col-12 p-0">
                                                <ki-input label="Identificación:"
                                                    placeholder="0 pixeles"
                                                    type="text"
                                                    full>
                                                </ki-input>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 p-0">
                                        <div class="row p-0" style="font-size: 12px;">

                                            <div class="col-12 p-0">
                                                <p class="m-0">
                                                    Posición:
                                                </p>
                                            </div>
                                            
                                            <div class="col-6 d-flex align-items-center p-0">
                                                <input name="position"
                                                    type="radio"/>
                                                <label class="pt-2 pl-2" 
                                                    for="position">
                                                    Izquierda
                                                </label>
                                            </div>
                                            <div class="col-6 d-flex align-items-center p-0">
                                                <input name="position"
                                                    type="radio"/>
                                                <label class="pt-2 pl-2"
                                                    for="position">
                                                    Derecha
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 p-0">
                                        <div class="row" style="font-size: 12px;">
                                            <div class="col-12">
                                                <p class="m-0">
                                                    Otros:
                                                </p>
                                            </div>
                                            <div class="col-12 d-flex flex-column align-items-start">
                                                <ki-checkbox label="BATAS/FILIPINAS" 
                                                    fill/>
                                                <ki-checkbox label="BEBES" 
                                                    fill/>
                                                <ki-checkbox label="BERMUDA XALOSTOC" 
                                                    fill/>
                                                <ki-checkbox label="BERMUDA/SHORT" 
                                                    fill/>
                                                <ki-checkbox label="BERMUDA/SHORT" 
                                                    fill/>
                                                <ki-checkbox label="BERMUDA/SHORT" 
                                                    fill/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="row p-0 col d-flex justify-content-end align-items-center">
                    <div class="col-2">
                        <button class="pills bg-primary-dark px-4 py-2 mt-3 mr-4 text-white">
                            Imprimir reporte
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    //Components
    import Titles from '../../components/Titles.vue'
    import TitleTabs from '../../components/TitleTabs.vue'

export default {
    name: 'ki-config-cash_register',
    components: {
        'titles': Titles,
        'title-tabs': TitleTabs,
    },
    data() {
        return {
            title_cashregister: [
                { name: 'Pantalla de caja', selected: true },
            ],
            titletab_active: null,
            pay_capture: null,
            font_size: null,
            base_line: null,
            cash_limit_in_box: null,
            quantity: null,
            product: null,
            price: null,
            total: null,
            code: null,
            in_existence: null,
            code_leght: null
        };
    },
    methods: {
        checkValues(value) {
            console.log(value)
        }
    },
    created(){
        for (let i in this.title_cashregister) {
            if(this.title_cashregister[i].selected){
                this.titletab_active = this.title_cashregister[i].name
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>